import { render, staticRenderFns } from "./FabricForm.vue?vue&type=template&id=24d9d185&scoped=true"
import script from "./FabricForm.vue?vue&type=script&lang=js"
export * from "./FabricForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d9d185",
  null
  
)

export default component.exports