<template>
  <div class="card">

    <div class="card-header header-elements-inline bg-success-400" >
      <h5 class="card-title">Fabric Creation</h5>
      <div class="header-elements">
        <div class="list-icons">

          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Article:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="fabric" v-model="fabric.article.code" >
              <option v-for="article in articles" v-bind:value="article.code">
                {{ article.name }}
              </option>
            </select>
          </div>
        </div>



        <div class="form-group row">
          <label class="col-md-2 col-form-label">Design:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="fabric" v-model="fabric.design.code">
              <option value="1">-</option>
              <option v-for="design in designs" v-bind:value="design.code">
                {{ design.name }}
              </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Color:</label>
          <div class="col-md-5">
            <select class="form-control" v-if="fabric" v-model="fabric.color.code">
              <option value="1">-</option>
              <option v-for="color in colors" v-bind:value="color.code">
                {{ color.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Quality:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="fabric" v-model="fabric.quality.code">
              <option value="1">-</option>
              <option v-for="quality in qualities" v-bind:value="quality.code">
                {{ quality.name }}
              </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Style:</label>
          <div class="col-md-5">
            <select class="form-control" v-if="fabric" v-model="fabric.style.code">
              <option value="1">-</option>
              <option v-for="style in styles" v-bind:value="style.code">
                {{ style.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Size:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="fabric" v-model="fabric.size.code">
              <option v-for="size in sizes" v-bind:value="size.code">
                {{ size.name }}
              </option>
            </select>
          </div>


          <label class="col-md-1 col-form-label">Weight:</label>
          <div class="col-md-1">
            <input type="number" class="form-control text-right" placeholder="Weight / Unit" min="0" v-if="fabric" v-model="fabric.weight">
          </div>

          <label class="col-md-1 col-form-label">Reed:</label>
          <div class="col-md-1">
            <input type="number" class="form-control text-right" placeholder="Reed" min="0" v-if="fabric" v-model="fabric.reed">
          </div>

          <label class="col-md-1 col-form-label">Pick:</label>
          <div class="col-md-1">
            <input type="number" class="form-control text-right" placeholder="Pick" min="0" v-if="fabric" v-model="fabric.pick">
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100" v-if="fabric" v-model="fabric.p_name">
          </div>
        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'FabricForm',
    data () {
      return {
        fabric: JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}'),
        articles: [],
        designs: [],
        colors:[],
        qualities: [],
        styles: [],
        sizes:[]
      }
    },
    props: {
        myfabric: {
          type: Object,
          default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
        }
    },
    beforeMount () {
      if(this.myfabric.code > 1){
        this.fabric = this.myfabric; // save props data to itself's data and deal with tms
      }
    },
    component: {

    },
    created () {
      this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
    },
    mounted () {
      let self = this;
      $('.form-control-select2').select2()

      self.loadGroup();
      self.loadColors();
      self.loadDesigns();
      self.loadQualities();
      self.loadStyles();
      self.loadSizes();
      // this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"sname":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","print_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"width":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeModal();
      },
      clear(){
        this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}');
      },
      loadAll(){
        this.loadGroup();
        this.loadColors();
        this.loadDesigns();
        this.loadQualities();
        this.loadStyles();
        this.loadSizes();
      },
      loadGroup(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/article/nature/12/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.articles = resp.data;
            //self.$parent.$parent.loadData();
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.colors = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.designs = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadQualities(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.qualities = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadStyles(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/style/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.styles = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadSizes(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.sizes = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;

        self.$data.fabric.design.code = parseInt(self.$data.fabric.design.code);
        self.$data.fabric.style.code = parseInt(self.$data.fabric.style.code);
        self.$data.fabric.color.code = parseInt(self.$data.fabric.color.code);
        self.$data.fabric.quality.code = parseInt(self.$data.fabric.quality.code);

        self.$data.fabric.process.code = 305
        self.$data.fabric.unit.code = parseInt(self.$data.fabric.unit.code);
        self.$data.fabric.weight = parseFloat(self.$data.fabric.weight);
        self.$data.fabric.reed = parseInt(self.$data.fabric.reed);
        self.$data.fabric.pick = parseInt(self.$data.fabric.pick);

        if(self.$data.fabric.article.code < 1){
          alert("Invalid Article");
          return
        }else if(self.$data.fabric.design.code < 1){
          alert("Invalid Design");
          return
        }else if(self.$data.fabric.color.code < 1){
          alert("Invalid Color");
          return
        }else if(self.$data.fabric.quality.code < 1){
          alert("Invalid Quality");
          return
        }else if(self.$data.fabric.style.code < 1){
          alert("Invalid Style");
          return
        }else if(self.$data.fabric.size.code < 1){
          alert("Invalid Size");
          return
        }

        const requestOptions = {
          method:  (self.$data.fabric.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.fabric)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}');
            self.$parent.$parent.loadData();
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
